import { Routes } from '@angular/router';
import { TokenGuard } from './common/guards/token.guard';
import { GeneralUrl } from './shared/constants/route.constants';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages.routes').then(p => p.routes)
    },
    {
        path: GeneralUrl.LOGIN,
        canActivate: [TokenGuard],
        loadComponent: () => import('./auth/login/login.component').then(c => c.LoginComponent)
    },
    {
        path: GeneralUrl.SIGNUP,
        canActivate: [TokenGuard],
        loadComponent: () => import('./auth/signup/signup.component').then(c => c.SignupComponent)
    },
    {
        path: GeneralUrl.FORGOT_PASSWORD,
        canActivate: [TokenGuard],
        loadComponent: () => import('./auth/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent)
    },
    {
        path: GeneralUrl.ACCESS_DENIED,
        loadComponent: () => import('./pages/errors/access-denied/access-denied.component').then(c => c.AccessDeniedComponent)
    },
    {
        path: '**',
        loadComponent: () => import('./pages/errors/not-found/not-found.component').then(c => c.NotFoundComponent)
    }
];