import { HttpInterceptorFn, HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import _ from 'lodash';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable } from 'rxjs';
import { Cookies } from 'src/app/shared/constants/cookie.constants';


/**
 * This function intercepts HTTP requests and allows modification of the request before it is sent or the response before it is returned.
 * Define the function-based interceptor Fn the Fn suffix in HttpInterceptorFn and HttpHandlerFn indicates that these are function types rather than class types.
 * @param request - The HTTP request to be intercepted.
 * @param next - The next function in the HTTP handler chain that should be called with the modified request.
 * @returns An observable of the HTTP event that represents the result of the request. This allows the interceptor to modify or handle the response.
 */
export const appInterceptorFn: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {

  // Define the allowed file upload URLs in an array
  const allowedFileUploadUrls: string[] = [
    'api/crew-member/dashboard/portfolio-upload',
    'api/crew-member/dashboard/application',

    'api/client/dashboard/portfolio-upload',
    'api/client/dashboard/create-project',
    'api/client/dashboard/update-project',
    'api/client/dashboard/send-offer-crew-member'
  ];

  // Inject the service (if needed)
  // const myService = inject(MyService);
  const cookieService = inject(SsrCookieService);

  let authToken = cookieService.get(Cookies.AUTH_TOKEN);
  // Log or use the service if necessary
  // console.log('Service value:', myService.getValue());
  console.log("Request: " + request.url);

  // Clone the request and set withCredentials to true
  request = request.clone({ withCredentials: true });
  /**
   * Allow files to be uploaded
   * Check if the request URL matches any of the allowed file upload URLs
   * request.method.includes("POST") || 
   */
  if (allowedFileUploadUrls.some(url => request.url.includes(url))) {
    console.log("FILES")
    request = request.clone({
      setHeaders: {
        "Authorization": "Bearer " + authToken
      }
    });

    return next(request); // Continue with the request
  }
  
  // For other requests, set the Content-Type to application/json
  if (!_.isEmpty(authToken)) {
    // Set the authentication token
    request = request.clone({
      setHeaders: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + authToken
      }
    });
  }

  return next(request);
};

