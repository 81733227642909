import { Injectable } from "@angular/core";
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: string): UrlTree {

   // console.log("------------------ URL Prase "+url);
      let dus = new DefaultUrlSerializer();

      url = url.replace(/%20|\s|\(|\)/g,'-');
      
      return dus.parse(url);

  }

  serialize(tree: UrlTree): any {
      let dus = new DefaultUrlSerializer(),
          path = dus.serialize(tree);
      // use your regex to replace as per your requirement.
      //console.log("------------------ URL Serialize ");
      return path.replace(/%20|\s|\(|\)/g,'-');
  }
}