import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { PreloadAllModules, provideRouter, UrlSerializer, withPreloading, withViewTransitions } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { tokenInterceptor } from '@interceptors/token.interceptor';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './common/etc/custom-overlay-container';
import { appInterceptorFn } from '@interceptors/app.interceptor';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { AuthService } from '@services/auth.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { progressInterceptor } from 'ngx-progressbar/http';
import { CustomDateAdapterModule } from './common/etc/custom-date-adapter.module';
import { CustomUrlSerializer } from './utils/custom-url-serializer';

export function jwtOptionsFactory(authService: AuthService) {
  return {
    tokenGetter: () => authService.getToken(),
    // allowedDomains: ['example.com'], // Replace with your API domain
    // disallowedRoutes: ['example.com/api/auth'] // Replace with routes to exclude
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withViewTransitions({ skipInitialTransition: true }),
      withPreloading(PreloadAllModules),  // comment this line for enable lazy-loading
    ),
    provideClientHydration(),
    provideAnimationsAsync(),
    importProvidersFrom(
      // Configure JwtModule with JWT_OPTIONS
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
          deps: [AuthService]
        }
      }),
      CustomDateAdapterModule
    ),
    provideHttpClient(withFetch(), withInterceptorsFromDi(), withInterceptors([appInterceptorFn, tokenInterceptor, progressInterceptor])), // The order in which you list the interceptors in the withInterceptors array is the order in which they are applied.
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: UrlSerializer, useClass: CustomUrlSerializer }, // Chris I added this
    SsrCookieService // Provide CookieService for use in AuthService
  ]
};

// Notes
// CookieService: Provides methods to manage cookies and is used in AuthService to get and set the JWT token.
// jwtOptionsFactory: Configured to use AuthService to retrieve the token from the cookie.